import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Navbar, Nav, NavDropdown, Modal } from 'react-bootstrap';
import { linkResolver } from "../../utils/linkResolver";
import './style.css';
import logoBlue from "../../images/brandbox-logo-blue.png"
import { useStaticQuery, graphql } from 'gatsby';

function NavBar() {

  const { navbar } =
  useStaticQuery(
    graphql`
      query {

        navbar: prismicNavbar {
          data {
            navbar_logo {
              url
              alt
            }
            dropdown_header {
              text
            }
            dropdown_items {
              dropdown_links {
                document {
                  ... on PrismicNavLink {
                    id
                    data {
                      link_name {
                        text
                      }
                      link_destination {
                        uid
                      }
                    }
                  }
                }
              }
            }
            navbar_links {
              nav_links {
                document {
                  ... on PrismicNavLink {
                    id
                    data {
                      link_name {
                        text
                      }
                      link_destination {
                        uid
                      }
                    }
                  }
                }
              }
            }
            navbar_button {
              document {
                ... on PrismicNavLink {
                  id
                  data {
                    link_name {
                      text
                    }
                    link_destination {
                      uid
                    }
                  }
                }
              }
            }
            mobile_menu_logo {
              url
              alt
            }
            navbar_logo_link {
              document {
                ... on PrismicHomePage {
                  id
                  uid
                }
              }
            }
          }
        }

      }
    `
  )

  const navData = navbar.data;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <React.Fragment>
      <Navbar className="brandbox-navbar text-med" expand="lg">
        <Navbar.Brand href={ linkResolver(navData.navbar_logo_link.document.uid) }>
          <img className="brandbox-logo" src={ navData.navbar_logo.url } alt={ navData.navbar_logo.alt } />
        </Navbar.Brand>
        <Navbar.Toggle onClick={handleShow} id="toggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto nav-link-wrapper">
            <NavDropdown title={ navData.dropdown_header.text }>
              {navData.dropdown_items.map((d,i) => {
                const dropItems = d.dropdown_links.document.data;
                return (
                  <NavDropdown.Item href={ linkResolver(dropItems.link_destination.uid) }>
                    { dropItems.link_name.text }
                  </NavDropdown.Item>
                )
              })}
            </NavDropdown>
            {navData.navbar_links.map((l, i) => {
              const linkItems = l.nav_links.document.data;
              return (
                <Nav.Link href={ linkResolver(linkItems.link_destination.uid) }>
                  { linkItems.link_name.text }
                </Nav.Link>
              )
            })}
            <Nav.Link href={ linkResolver(navData.navbar_button.document.data.link_destination.uid) }>
              <button className="nav-button">
                { navData.navbar_button.document.data.link_name.text }
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <a href="/">
              <img src={logoBlue} alt="Brandbox Logo" />
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NavDropdown title="What's in Brandbox">
            <NavDropdown.Item href="/brand-story">
              Brand Story
            </NavDropdown.Item>
            <NavDropdown.Item href="/visual-identity">
              Visual Identity
            </NavDropdown.Item>
            <NavDropdown.Item href="/content-guidelines">
              Content Guidelines
            </NavDropdown.Item>
            <NavDropdown.Item href="/component-library">        Component Library
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/showcase">
            Showcase
          </Nav.Link>
          <Nav.Link href="/pricing">
            Pricing
          </Nav.Link>
          <Nav.Link href="http://www.brandbox.site">
            Login
          </Nav.Link>
          <Nav.Link href="/demo">
            Demo
          </Nav.Link>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

NavBar.propTypes = {
  data: PropTypes.shape({
    prismicNavbar: PropTypes.shape({
      data: PropTypes.shape({
        navbar_logo: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        dropdown_header: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        dropdown_items: PropTypes.array.isRequired,
        navbar_links: PropTypes.array.isRequired,
        navbar_button: PropTypes.shape({
          document: PropTypes.array.isRequired,
        }),
        mobile_menu_logo: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        navbar_logo_link: PropTypes.shape({
          document: PropTypes.array.isRequired,
        }),
      }),
    }),
  }),
}

export default NavBar;