import React from "react";
import Helmet from "react-helmet";

export default ({ title, description, image, alt, creator, site, card }) => (
  <Helmet>
    <meta property="twitter:title" content={ title } />
    <meta property="twitter:description" content={ description } />
    <meta property="twitter:image" content={ image } />
    <meta property="twitter:image:alt" content={ alt } />
    <meta property="twitter:creator" content={ creator } />
    <meta property="twitter:site" content={ site } />
    <meta property="twitter:card" content={ card } />
  </Helmet>
)