import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import Facebook from "./facebook";
import Twitter from "./twitter";

function SEO({ description, lang, title, author }) {
  const { site, seoTwit, seoFace } = useStaticQuery(
    graphql`
      query {

        site {
          siteMetadata {
            title
            description
            author
            faceTitle
            faceDescription
            faceUrl
            faceImage
            faceAlt
            twitTitle
            twitDescription
            twitCard
            twitImage
            twitAlt
            twitCreator
            twitSite
          }
        }

        seoTwit: prismicHomePageBody1TwitterCard {
          primary {
            twitter_card
            twitter_site
            twitter_creator
            twitter_title
            twitter_description
            twitter_image {
              url
              alt
            }
          }
        }

        seoFace: prismicHomePageBody1FacebookCard {
          primary {
            facebook_title
            facebook_description
            facebook_url {
              url
            }
            facebook_image {
              url
              alt
            }
          }
        }

      }
    `
  )

  const twitterData = seoTwit.primary;
  const facebookData = seoFace.primary;
  const siteMeta = site.siteMetadata;

  const seo = {
    title: title || siteMeta.title,
    description: description || siteMeta.description,
    author: author || siteMeta.author,

    faceTitle:
    facebookData.facebook_title || siteMeta.faceTitle,
    faceDescription:
    facebookData.facebook_description || siteMeta.faceDescription,
    faceUrl:
    facebookData.facebook_url.url || siteMeta.faceUrl,
    faceImage:
    facebookData.facebook_image.url || siteMeta.faceImage,
    faceAlt:
    facebookData.facebook_image.alt || siteMeta.faceAlt,

    twitTitle:
    twitterData.twitter_title || siteMeta.twitTitle,
    twitDescription:
    twitterData.twitter_description || siteMeta.twitDescription,
    twitCard:
    twitterData.twitter_card || siteMeta.twitCard,
    twitImage:
    twitterData.twitter_image.url || siteMeta.twitImage,
    twitAlt:
    twitterData.twitter_image.alt || siteMeta.twitAlt,
    twitCreator:
    twitterData.twitter_creator || siteMeta.twitCreator,
    twitSite:
    twitterData.twitter_site || siteMeta.twitSite,
  }

  return (
    <React.Fragment>
    <Helmet
      title={ seo.title } 
      titleTemplate={`%s | ${ site.siteMetadata.title }`}
      >
      <meta name="description" content={ seo.description } />
    </Helmet>
    <Facebook
      title={ seo.faceTitle }
      description={ seo.faceDescription }
      image={ seo.faceImage }
      alt={ seo.faceAlt }
      url={ seo.faceUrl }
      />
    <Twitter
      title={ seo.twitTitle }
      description={ seo.twitDescription}
      image={ seo.twitImage }
      alt={ seo.twitAlt }
      creator={ seo.twitCreator }
      site={ seo.twitSite }
      card={ seo.twitCard }
      />
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  data: PropTypes.shape({
    prismicHomePageBody1TwitterCard: PropTypes.shape({
      primary: PropTypes.shape({
        twitter_card: PropTypes.string.isRequired,
        twitter_site: PropTypes.string.isRequired,
        twitter_creator: PropTypes.string.isRequired,
        twitter_title: PropTypes.string.isRequired,
        twitter_description: PropTypes.string.isRequired,
        twitter_image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
      }),
    }),
    prismicHomePageBody1FacebookCard: PropTypes.shape({
      primary: PropTypes.shape({
        facebook_title: PropTypes.string.isRequired,
        facebook_description: PropTypes.string.isRequired,
        facebook_url: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
        facebook_image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
