import React from "react";
import Helmet from "react-helmet";

export default ({ title, description, image, url, siteName }) => (
  <Helmet>
    <meta property="og:title" content={ title } />
    <meta property="og:description" content={ description } />
    <meta property="og:image" content={ image } />
    <meta property="og:url" content={ url } />
  </Helmet>
)