import React from 'react';
import PropTypes from "prop-types";
import { linkResolver } from "../../utils/linkResolver";
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import './style.css';

function Footer() {

  const { footer } =
  useStaticQuery(
    graphql`
      query {

        footer: prismicFooter {
          data {
            copyright {
              text
            }
            footer_links {
              footer_link_items {
                document {
                  ... on PrismicNavLink {
                    id
                    data {
                      link_name {
                        text
                      }
                      link_destination {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    `
  )

  const footerData = footer.data;

  return(
    <footer className="page-footer">
      <div className="footer-copyright-wrapper">
        <h4 className="footer-copyright text-light">
          { footerData.copyright.text }
        </h4>
      </div>
      <div className="footer-links-wrapper ml-auto">
        <ul className="footer-list">
          { footerData.footer_links.map((f, i) => {
            const footerItems = f.footer_link_items.document.data;
            return (
              <li className="list-unstyled">
                <Link className="footer-link text-med" to={ linkResolver(footerItems.link_destination.uid) }>
                  { footerItems.link_name.text }
                </Link>
              </li>
            )
          })}
        </ul>
        {/* <ul className="footer-list-left">
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/brand-story">
              SOLUTIONS
            </Link>
          </li>
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/showcase">
              SHOWCASE
            </Link>
          </li>
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/pricing">
              PRICING
            </Link>
          </li>
          <li className="list-unstyled">
            <Link to="/demo" className="footer-link text-med">
              DEMO
            </Link>
          </li>
        </ul>
        <ul className="footer-list-right">
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/support">
              SUPPORT
            </Link>
          </li>
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/about">
              ABOUT
            </Link>
          </li>
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/careers">
              CAREERS
            </Link>
          </li>
          <li className="list-unstyled">
            <Link className="footer-link text-med" to="/contact">
              CONTACT
            </Link>
          </li>
        </ul> */}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  data: PropTypes.shape({
    prismicFooter: PropTypes.shape({
      data: PropTypes.shape({
        copyright: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        footer_links: PropTypes.array.isRequired,
      }),
    }),
  }),
}

export default Footer;